import React from "react";
import {withFormsy} from "formsy-react";
import {TextField} from "../bootstrap/text-field";

// 
// @autobind
class Input extends React.Component {
  constructor(props) {
    super(props);
    if (props.defaultValue) {
      return props.setValue(props.defaultValue);
    }
    if (props.override) {
      return this.handleOverride();
    }
    return undefined;
  }
  handleChangeValue = async(e, value) => {
    if (this.props.onChange) {
      value = await this.props.onChange(e, value);
    }
    this.props.setValue(value);
    return value;
  }
  handleOnFocus = async (e) => {
    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
    if (this.props.clearOnFocus) {
      this.props.setValue("");
    }
    return undefined;
  }
  handleOverride = async() => {
    return this.props.setValue(this.props.override);
  }
  render = () => {
    const {onChange, onFocus, isValid, errorMessage, showError, value, ...rest} = this.props;
    // var value = value;
    if (this.props.override && value !== this.props.override) {
      this.handleOverride();
    }
    return (
      <TextField {...rest}
        onFocus={(e) => this.handleOnFocus(e)}
        onChange={(e, value) => this.handleChangeValue(e, value)}
        isValid={!showError}
        value={value}
        invalidFeedback={errorMessage}
      />
    );
  }
}


export default withFormsy(Input);
