import React from "react";
import PropTypes from "prop-types";

const Button = (props) => {
  const { testid, className, block = false, small = false, large = false, outline = false, colour = "primary", loading = false, ...rest} = props;
  let value;
  let child;
  let hasOutline = (outline) ? "-outline btn-outline" : "";
  let newClassName = `${className || ""} btn btn${hasOutline}-${colour}`;
  let {disabled} = props;
  disabled = loading ? loading : !!disabled;
  if (small) {
    newClassName += " btn-sm";
  } else if (large) {
    newClassName += " btn-lg";
  }
  if (block) {
    newClassName += " btn-block";
  }
  let type;
  let Component = "button";
  switch (props.type) {
    case "link":
      Component = "a";
      child = props.value || props.children;
      break;
    case "reset":
      Component = "input";
      type = props.type;
      value = props.value || props.children;
      break;
    case "submit":
    case "button":
    default:
      Component = "button";
      child = props.value || props.children;
      break;
  }

  return (
    <Component testid={testid} type={type} data-toggle="button" className={newClassName} value={value} disabled={disabled} {...rest}>
      {loading ? (
        <div className={`spinner ${colour === "light" ? " blue" : ""}`}>
          <div className="bounce1"/>
          <div className="bounce2"/>
          <div className="bounce3"/>
        </div>
      ) : (child)}
    </Component>
  );
};

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  colour: PropTypes.oneOf(["danger", "light", "primary", "secondary", "white"]),
  loading: PropTypes.bool,
  outline: PropTypes.bool,
  testId: PropTypes.string,
};

export default Button;
