
import root from "window-or-global";

import config from "../../config"
/**
 * @param {String} url
 * @param {Object} data
 * @returns {Object}
 */
const post = async(url, data) => {
  const result = await root.fetch(url, {
    headers: {"Content-Type": "application/json"},
    method: "POST",
    body: JSON.stringify(data),
  });

  const parsed = await result.json();
  return {
    Response: result,
    ...parsed,
  };
};

const get = async(url, mode, credentials, parseJson) => {
  const response = await root.fetch(url, {
    method: "GET",
    mode,
    credentials,
  });
  if (parseJson) {
    return response.json();
  }
  return response.text();
};

const createJSON = async(response) => {
  let data;
  try {
    data = await response.json();
    if (response.status === 200) {
      return data;
    }
  } catch (e) {
    console.log(e);
  }

  return {
    status: response.status,
    message: response.statusText,
    [data.errors ? "errors" : "data"]: data.errors ? data.errors : data,
  };
}


const gql = async(query, variables) => {
  return fetch(`${config.apiUrl}/graphql.api`, {
    credentials: "include",
    method: "post",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      query,
      variables,
    }),
  }).then(createJSON);
}

export default {
  post,
  get,
  gql,
};
