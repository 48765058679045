import React from "react";
export default function Row(props) {
  let className = props.className || "";
  if(props.reverse) {
    className += " row-reverse";
  }
  return (<div {...props} testid={props.testid} className={`row ${className}`}/>);
}
Row.displayName = "Row";

