import React from "react";
const gridOptions = ["xs", "sm", "md", "lg", "xl"];

function getGridOptions(ext, props = {}) {
  let lext = `-${ext}`;
  if (ext === "xs") {
    lext = "";
  }
  let classNames = [];
  // if (props[`${ext}Offset`]) {
  //   classNames.push(`col-${lext}-offset-${props[`${ext}Offset`]}`);
  //   delete props[`${ext}Offset`];
  // }
  if (props[`${ext}`]) {
    if (props[`${ext}`] === true) {
      classNames.push(`col${lext}`);
    } else {
      classNames.push(`col${lext}-${props[`${ext}`]}`);
    }
    delete props[`${ext}`];
  }
  // if (props[`visible${ext}`]) {
  //   classNames.push(`visible-${ext}`);
  //   delete props[`visible${ext}`];
  // }

  if (classNames.length > 0) {
    if (!props.className) {
      props.className = "";
    }
    props.className += ` ${classNames.join(" ")}`;
  }
  return props;
}

export default function Col(props, context) {
  let propsData = gridOptions.reduce((prop, ext) => {
    return getGridOptions(ext, prop);
  }, Object.assign({className: ""}, props));
  if (propsData.className.indexOf("col") === -1) {
    propsData.className = `${propsData.className || "" } col`;
  }

  return (<div {...propsData}>
    {props.children}
  </div>);
}
Col.displayName = "Column";
