import React from "react";
import PromiseComponent from "../promise";

import {findDOMNode} from "react-dom";
// import {getElementPosition} from "../../../utils/browser";
import Col from "./col";
import Row from "./row";
import uuid from "uuid/v4";
const ibtoa = str => {
  try {
      return btoa(str);
  } catch(err) {
      return Buffer.from(str).toString('base64')
  }
}; 

export class TextField extends PromiseComponent {
  constructor() {
    super();
    this.fieldName = ibtoa(uuid());
    this.state = {
      autoComplete: undefined,
    };
  }
  componentDidMount() {
    if (this.props.onInputSizeReady) {
      this.props.onInputSizeReady(getElementPosition(this.getInputGroupElement()));
    }
    if (this.props.autoFocus) {
      setTimeout(() => {
        const element = this.getInputGroupElement();
        if (element) {
          element.querySelector("input:first-child").focus();
        }
      }, 100);
    }
  }

  getInputGroupElement = () => {
    return findDOMNode(this.inputGroup);
  }

  getInputElement = () => {
    return findDOMNode(this.input);
  }
  render() {
    const {props} = this;
    let labelText;
    if (props.label) {
      labelText = props.label;
    }
    let label = props.label ? (<label htmlFor={this.fieldName} style={props.noLabel ? {color: "#fff"} : {}}>{labelText}</label>) : undefined;
    const invalidId = ibtoa(uuid());
    const textField = (<React.Fragment>
      <div ref={(r) => (this.inputGroup = r)} className={`input-group ${props.isValid === false || props.isRequired ? "is-invalid" : ""} field ${props.className || ""} ${((!props.value || props.value === "") ? "" : "hasValue")} ${props.inputGroupPrepend ? "input-prepend" : ""}`}>
        {(props.inputGroupPrepend) ? (<div className="input-group-prepend">{props.inputGroupPrepend}</div>) : undefined}
        <input autoComplete={this.state.autoComplete} ref={(r) => (this.input = r)}
          style={this.props.inputStyle}
          autoFocus={props.autoFocus}
          maxLength={props.maxLength}
          placeholder={props.placeholder}
          className={`form-control ${props.isValid === false ? "is-invalid" : ""}`}
          required={props.required} onChange={(e) => props.onChange ? props.onChange(e, e.currentTarget.value) : undefined}
          onKeyPress={props.onKeyPress}
          onKeyDown={props.onKeyDown}
          onFocus={(async(e) => {
            await this.setStateAsync({
              autoComplete: ibtoa(uuid()),
            });
            if (props.onFocus) {
              return props.onFocus(e);
            }
            return undefined;
          })}
          onBlur={(e) => (props.onBlur ? props.onBlur(e) : undefined)}
          type={props.type || "text"}
          min={props.type === "number" && props.min || 1}
          max={props.type === "number" && props.max || 99999999}
          name={this.fieldName}
          value={props.value || ""}
          formNoValidate disabled={props.disabled}
          readOnly={props.readOnly}
          aria-describedby={!props.isValid || props.invalidFeedback ? `#${invalidId}` : undefined }
          aria-invalid={!props.isValid ? "true" : (props.isRequired ? "false" : undefined)}
          testid={process.env.NODE_ENV !== "production" ? props.testid : undefined}/>
        {(props.inputGroupAppend) ? (<div className="input-group-append">{props.inputGroupAppend}</div>) : undefined}
      </div>
      {props.invalidFeedback && !props.isValid ? <div id={invalidId} style={{display: "inherit"}} className="invalid-feedback">{props.invalidFeedback}</div> : undefined}
    </React.Fragment>);
    if (props.inline) {
      return (<Row className="align-items-center inline">
        {props.label ? (<label htmlFor={this.fieldName} className="col-3">{labelText}</label>) : undefined}
        <Col>
          {textField}
          {props.afterTextField}
        </Col>
      </Row>);
    }
    return (<React.Fragment>
      {label}
      {textField}
    </React.Fragment>);
  }
}


export class ManagedTextField extends PromiseComponent {
  constructor(props) {
    super();
    this.state = {
      value: props.defaultValue,
    };
    return undefined;
  }
  async handleChangeValue(e) {
    const {value} = e.target;
    let val = value;
    if (this.props.onChange) {
      val = await this.props.onChange(e, value);
    }
    return this.setStateAsync({value: val});
  }
  async handleOnFocus(e) {
    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
    if (this.props.clearOnFocus) {
      await this.setStateAsync({value: ""});
    }
    return undefined;
  }
  render() {
    const {onChange, onFocus, ...rest} = this.props;
    return (<TextField {...rest} onFocus={(e) => this.handleOnFocus(e)} onChange={(e) => this.handleChangeValue(e)} value={this.state.value} />);
  }
}
